import React from 'react';
import { Container } from 'react-bootstrap';

import Framework from '../../components/framework';
import NavTop from '../../components/framework/nav-top';
import Seo from '../../components/framework/seo';
import LegalAgreement from '../../components/legal/agreement';
import LegalBreadcrumb from '../../components/legal/breadcrumb';

const LegalPrivacy = () => {
	return (
		<Framework>
			<NavTop />

			<Container className="mt-5 pt-5">
				<LegalAgreement type="privacy_policy">
					{(agreement) => (
						<>
							<Seo title={agreement?.title} />
							<LegalBreadcrumb title={agreement?.title} />
						</>
					)}
				</LegalAgreement>
			</Container>
		</Framework>
	);
};

export default LegalPrivacy;
