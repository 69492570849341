import PropTypes from 'prop-types';
import React from 'react';

import useRequest from '../../hooks/use_request';
import useTranslation from '../../hooks/use_translation';
import Suspense from '../framework/suspense';
import Trans from '../translation/trans';

const Agreement = ({ children, type }) => {
	return (
		<Suspense tags={{ component: 'LegalAgreement' }}>
			<Request type={type}>{children}</Request>
		</Suspense>
	);
};

const Request = ({ children, type }) => {
	const { t } = useTranslation('component_legal_agreement');
	const { data: response_agreement_data } = useRequest(
		'content_agreement.data',
		{
			params: {
				type,
			},
		}
	);
	const agreement = response_agreement_data?.data;

	return (
		<>
			{children && <>{children(agreement)}</>}
			<h1>{agreement?.title}</h1>
			{agreement?.published_at && (
				<p className="small">
					{t('t1')} {agreement?.published_at?.substring(0, 10)}
				</p>
			)}
			<Trans defaults={agreement?.content} t={t} />
		</>
	);
};

Agreement.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string,
};

Request.propTypes = {
	children: PropTypes.node,
	type: PropTypes.string,
};

export default Agreement;
