import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

import useTranslation from '../../hooks/use_translation';
import Link from '../framework/link';

const LegalBreadcrumb = ({ title }) => {
	const { t } = useTranslation('component_legal_breadcrumb');

	return (
		<Breadcrumb>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
				{t('t1')}
			</Breadcrumb.Item>
			<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/legal/' }}>
				{t('t2')}
			</Breadcrumb.Item>
			<Breadcrumb.Item active>{title}</Breadcrumb.Item>
		</Breadcrumb>
	);
};

LegalBreadcrumb.propTypes = {
	title: PropTypes.string,
};

export default LegalBreadcrumb;
